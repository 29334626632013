
import { defineComponent, inject } from "vue";
import SystemIcon from '@/components/SystemIcon'
import WindowApi from "../../core/WindowApi";
import { useWindow } from "@/hooks/window/useWindow";

const WindowHeaderProps = {
  title: {
    type: String
  },
};

export default defineComponent({
  name: 'OsWindowHeader',
  components: { SystemIcon },
  props: WindowHeaderProps,
  setup(props) {
    let onMove = false,
      lastClientX = 0,
      lastClientY = 0

    const windowApi = inject('window') as WindowApi

    const onHeaderMousedown = () => {
      onMove = true
      windowApi.setTransition(false)
    }

    const onHeaderMove = (e: MouseEvent) => {
      if (!onMove || lastClientX === 0 || lastClientY === 0) {
        lastClientX = e.clientX
        lastClientY = e.clientY
      }

      windowApi?.setPositionTo(e.clientX - lastClientX, e.clientY - lastClientY)

      lastClientX = e.clientX
      lastClientY = e.clientY
    }

    document.body.addEventListener('mousemove', onHeaderMove)

    const onHeaderMouseup = () => {
      onMove = false
      lastClientX = lastClientY = 0
      windowApi.setTransition(true)
    }

    document.body.addEventListener('mouseup', onHeaderMouseup)

    const handleMaximum = () => windowApi.maximum()
    const handleClose = () => windowApi.close()
    const handleMinimize = () => windowApi.minimize()

    return {
      onHeaderMousedown,
      handleMaximum,
      handleClose,
      handleMinimize
    }
  }
})
