
import { defineComponent, getCurrentInstance, PropType, provide } from "vue";
import { getWindowStyle, useWindowResize } from './useWindow'
import WindowApi from "../../core/WindowApi";
import WindowHeader from './header.vue'
import { WindowItem } from "../../types";
import { useWindowManager } from "@/hooks/desktop/useWindowManager";

const WindowProps = {
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
  width: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 0,
  },
  zIndex: {
    type: Number,
    default: 5
  },
  title: {
    type: String
  },
  transition: {
    type: Boolean,
    default: false
  },
  window: {
    required: true,
    type: Object as PropType<WindowItem>
  },
};

export default defineComponent({
  name: 'OsWindow',
  components: { WindowHeader },
  props: WindowProps,
  setup(props) {
    const instance = getCurrentInstance()
    const windowStyle = getWindowStyle()
    const windowApi = new WindowApi(props.window as WindowItem, useWindowManager())

    provide('window', windowApi)

    useWindowResize(windowApi)

    const onWindowClick = () => windowApi.addZindex()

    return {
      slots: instance?.slots,
      windowStyle,
      onWindowClick,
      windowApi
    }
  }
})
