
import { computed, CSSProperties, defineComponent } from 'vue';

export default defineComponent({
  name: 'OsIconSvg',
  props: {
    prefix: {
      type: String,
      default: 'icon',
    },
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 13,
    },
    spin: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  setup(props) {
    const icon = computed(() => '#os-' + props.name);

    const iconStyle = computed((): CSSProperties => {
      const { size } = props

      const sizeUnit = (+size * 0.0625) + 'rem'

      return {
        width: sizeUnit,
        height: sizeUnit,
        fill: props.color
      }
    })

    return { icon, iconStyle }
  },
});
